/*.article {*/
/*    height: 50%;*/
/*    position: relative;*/
/*    overflow: hidden;*/
/*}*/

.picture {
    /*object-fit: cover;*/
    width: 50%;
    height: 50%;
}

.header {
    font-size: 25px;
    color: white;
    text-align: center;
    margin-top: -218px;
    /*font-size: 30px;*/
    /*color: white;*/
    /*text-align: center;*/
    /*position: absolute;*/
    /*top: -300px;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*height: fit-content;*/
    /*margin: auto;*/
}.score {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-top: -30px;

    /*position: absolute;*/
    /*top: 0px;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*height: fit-content;*/
    /*margin: auto;*/
}
.test{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}
