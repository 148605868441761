#myVideo{
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    /*height: 100%;*/
}

.overlay{
    height: 100%;
    /*height: 100%;*/
    width: inherit;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0;
    background: dimgray;
    opacity: 0.5;
}

#container{
    height: 50vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
}
